import { route } from 'quasar/wrappers'
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
  Router
} from 'vue-router'
import { StateInterface } from '../store'
import routes from './routes'
import { currentEmployeeId, hasRole, isLoggedIn } from 'src/router/middleware/auth'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

let myRouter:Router

export default route<StateInterface>(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE
    )
  })

  Router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresLogin) {
      const userLoggedIn = await isLoggedIn()
      userLoggedIn ? next() : next({ name: 'Login' })
    } else if (to.meta.requiresAnyOfRoles) {
      const userHasRole = await hasRole(to.meta.requiresAnyOfRoles as string[])
      userHasRole ? next() : next({ name: 'Login' })
    } else if (to.meta.requiresGuest) {
      const userIsGuest = !await isLoggedIn()
      userIsGuest ? next() : next({ name: 'Dashboard' })
    } else {
      next()
    }
  })

  Router.beforeEach(async (to, from, next) => {
    const employeeId = await currentEmployeeId()
    if (to.name === 'Timesheet fill for employee') {
      next({ name: 'Timesheet by Employee', params: { employeeId: employeeId } })
    }
    if (to.name === 'Timesheet by Employee' && to.params.employeeId !== employeeId) {
      next({ name: 'Timesheet by Employee', params: { employeeId: employeeId } })
      // to.params.employeeId = employeeId
    }
    next()
  })

  myRouter = Router

  return Router
})

export { myRouter }
