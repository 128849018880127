const Parse = require('parse')

const appId = process.env.PARSE_APPID
const serverUrl = process.env.PARSE_SERVERURL

Parse.initialize(appId)
Parse.serverURL = serverUrl
Parse.enableEncryptedUser()
Parse.secret = process.env.PARSE_ENCRYPTIONSECRET

export default Parse
