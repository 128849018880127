import { Loading, QSpinnerRings } from 'quasar'
// "async" is optional;
// more info on params: https://quasar.dev/quasar-cli/boot-files
// export default async ({ store, router, Vue }) => {
export default async ({ store }) => {
  Loading.show({
    spinner: QSpinnerRings,
    spinnerColor: 'primary',
    spinnerSize: 100,
    backgroundColor: 'white'
  })
  await store.dispatch('auth/currentUser')
  Loading.hide()
}
