import { MutationTree } from 'vuex'
import { AuthStateInterface, AuthMutationList, UserDetails } from 'src/types/store/auth'

const mutations: MutationTree<AuthStateInterface> = {
  [AuthMutationList.SET_ERRORS] (state:AuthStateInterface, payload:Record<string, unknown>) {
    // @ts-ignore
    if (payload === {}) {
      state.authError = {}
    } else {
      state.authError = payload
    }
  },
  [AuthMutationList.SET_USER] (state:AuthStateInterface, payload: UserDetails) {
    if (payload.id) {
      state.userDetails = payload
      // state.userDetails.userId = payload.id
      // state.userDetails.roles = payload.Roles
    } else { // @ts-ignore
      if (payload === {}) {
        state.userDetails = {}
      }
    }
  }
}

export default mutations
