import { GetterTree } from 'vuex'
import { AuthGetterList, AuthStateInterface } from 'src/types/store/auth'
import { StateInterface } from 'src/store'

const getters: GetterTree<AuthStateInterface, StateInterface> = {
  [AuthGetterList.getUserDetails] (state) {
    return state.userDetails
  },
  [AuthGetterList.getUserId] (state) {
    return state.userDetails.id
  }
}

export default getters
