const state = {}
const mutations = {}
const actions = {
  setGlobalSettings () {
    return true
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
