import { Sentry } from 'src/boot/initSentryClient'
import { ActionTree } from 'vuex'
import {
  AuthActionList,
  AuthMutationList,
  AuthStateInterface,
  LoginCredentials,
  UserDetails
} from 'src/types/store/auth'
import { StateInterface } from 'src/store'
import { Role, User } from 'parse'
import Parse from 'src/api/api'
import { myRouter } from 'src/router'

const actions: ActionTree<AuthStateInterface, StateInterface> = {
  async [AuthActionList.makeUserData] ({
    dispatch
  }, payload: User) {
    const user = payload
    try {
      const roles = await new Parse.Query(Parse.Role).equalTo('users', user).find()
      // const roles = payload.roles
      const userDetails: UserDetails = {}
      const roleNames = roles.map((role: Role) => {
        return role.attributes.name
      })
      userDetails.id = user.id
      userDetails.name = user.attributes.name
      userDetails.roles = roleNames
      userDetails.objects = {}
      userDetails.objects.user = user
      userDetails.objects.roles = roles
      userDetails.hasRole = function (roleNamesArr: string[]) {
        return roleNamesArr.some((role: string) => this.roles ? this.roles.includes(role) : false)
      }
      await dispatch(AuthActionList.setUserDetails, userDetails)
      // commit(AuthMutationList.SET_USER, userDetails)
      return userDetails
    } catch (error) {
      Sentry.captureException(error)
      await dispatch(AuthActionList.redirectUser)
    }
  },

  async [AuthActionList.loginUser] ({
    dispatch,
    commit
  }, payload: LoginCredentials) {
    try {
      const user: User = await Parse.User.logIn(payload.identifier, payload.password)
      await dispatch('setting/setGlobalSettings', {}, { root: true })
      await dispatch(AuthActionList.makeUserData, user)
      return user
    } catch (error: unknown) {
      commit(AuthMutationList.SET_ERRORS, { login: error })
      return error
    }
  },

  [AuthActionList.clearAuthErrors] ({ commit }) {
    commit(AuthMutationList.SET_ERRORS, {})
  },

  async [AuthActionList.logoutUser] ({ dispatch }) {
    await dispatch(AuthActionList.setUserDetails, {})
    await Parse.User.logOut()
    window.location.reload()
  },

  async [AuthActionList.currentUser] ({ dispatch, state }) {
    const user = await Parse.User.current()
    if (!user) {
      await dispatch(AuthActionList.setUserDetails, {})
    } else {
      await dispatch('setting/setGlobalSettings', {}, { root: true })
      await dispatch(AuthActionList.makeUserData, user)
    }
    return state.userDetails
  },

  async [AuthActionList.redirectUser] ({ state }, redirectToPath?: string) {
    if (!state.userDetails.id) {
      await myRouter.push({ name: 'Login' })
    }
    if (redirectToPath) {
      await myRouter.push(redirectToPath)
    } else {
      await myRouter.push({ name: 'Dashboard' })
    }
  },

  [AuthActionList.setUserDetails] ({ commit }, payload: UserDetails) {
    commit(AuthMutationList.SET_USER, payload)

    type SentryUser = {id: string, email: string} | null
    let sentryUserToSet: SentryUser = { id: '', email: '' }
    if (payload.id) {
      sentryUserToSet.id = payload.id
      sentryUserToSet.email = payload.objects?.user?.get('email')
    } else {
      sentryUserToSet = null
    }
    Sentry.setUser(sentryUserToSet)
  }
}

export default actions
