// import Parse from 'src/api/api'
// import { Role, User } from 'parse'
// import { myRouter } from 'src/router'
import { AuthStateInterface } from 'src/types/store/auth'
import mutations from 'src/store/auth/mutations'
import actions from 'src/store/auth/actions'
import getters from 'src/store/auth/getters'

function state ():AuthStateInterface {
  return {
    userDetails: {},
    authError: {}
  }
}

/*
const state = {
  userDetails: {},
  authError: {}
}
*/
/*
const mutations = {
  SET_ERRORS (state:any, payload:any) {
    if (payload === {}) {
      state.authError = {}
    } else {
      state.authError = payload
    }
  },
  SET_USER (state:any, payload:any) {
    // state.userDetails = payload
    if (payload.userId || payload.id) {
      state.userDetails = payload
      state.userDetails.userId = payload.id
      // state.userDetails.roles = payload.Roles
    } else if (payload === {}) {
      state.userDetails = {}
    }
  }
}
 */

/*
const actions = {
  async makeUserData ({ commit, dispatch }:any, payload:any) {
    const user = payload
    try {
      const roles = await new Parse.Query(Parse.Role).equalTo('users', user).find()
      // const roles = payload.roles
      const userDetails:any = {}
      const roleNames = roles.map((role:Role) => {
        return role.attributes.name
      })
      userDetails.id = user.id
      userDetails.name = user.attributes.name
      userDetails.roles = roleNames
      userDetails.objects = {}
      userDetails.objects.user = user
      userDetails.objects.roles = roles
      userDetails.hasRole = function (roleNamesArr:string[]) {
        return roleNamesArr.some((role:string) => this.roles.includes(role))
      }
      commit('SET_USER', userDetails)
      return userDetails
    } catch (error) {
      dispatch('redirectUser')
    }
  },

  async loginUser ({ dispatch, commit }:any, payload:any) {
    try {
      const user:User = await Parse.User.logIn(payload.identifier, payload.password)
      await dispatch('setting/setGlobalSettings', {}, { root: true })
      await dispatch('makeUserData', user)
      return user
    } catch (error:any) {
      commit('SET_ERRORS', { login: error })
      return error
    }
    // Parse.User.logIn(payload.identifier, payload.password).then((user:User) => {
    //   // console.log (user)
    //   dispatch('setting/setGlobalSettings', {}, { root: true })
    //   dispatch('makeUserData', user)
    //   return user
    // }).catch((error:any) => {
    //   // console.log(error)
    //   commit('SET_ERRORS', { login: error })
    //   return error
    // })
  },

  clearAuthErrors ({ commit }:any) {
    commit("SET_ERRORS", {})
  },
  async logoutUser ({ dispatch }:any) {
    console.log("logging out")
    await dispatch('setUserDetails', {})
    await Parse.User.logOut()
    // this.$router.push('/auth/login')
    location.reload()
  },

  async currentUser ({ dispatch, state }:any) {
    const user = await Parse.User.current()
    if (!user) {
      dispatch('setUserDetails', {})
      // this.$router.push({ name: 'Login' })
    } else {
      dispatch('setting/setGlobalSettings', {}, { root: true })
      await dispatch('makeUserData', user)
    }
    // dispatch('redirectUser')
    return state.userDetails
  },

  async redirectUser ({ state }:any) {
    // try {
    if (!state.userDetails.id) {
      // await this.$router.push({ name: 'Login' })
      // console.log(myRouter)
      await myRouter.push({ name: 'Login' })
      // return
    }
    // await this.$router.push({ name: 'Dashboard' })
    // console.log('redirecting to dashboard')
    await myRouter.push({ name: 'Dashboard' })
    // return
    // } catch (error) {
    //   console.log(error)
    //   return { error: error }
    // }

    // if(state.userDetails.lecturer_info){
    //   this.$router.push({name: 'Home'})
    // }
    // if((state.userDetails.roles) && (state.userDetails.roles.includes("Parent"))){
    //   this.$router.push({name:'ParentStudentDetails'})
    // }
  },

  setUserDetails ({ commit }:any, payload:any) {
    commit('SET_USER', payload)
  }
}
*/

/*
const getters = {
  getUserDetails: (state:any) => {
    return state.userDetails
  },
  getUserId: (state:any) => {
    return state.userDetails.id
  }
}
*/

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
