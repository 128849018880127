// import { useStore } from 'src/store'
import { myStore as store } from 'src/store'

const isLoggedIn = async () => {
  if (await store.getters['auth/getUserId']) {
    return true
  } else {
    return false
  }
}

const hasRole = async (roleNames:string[]) => {
  if (await store.getters['auth/getUserId']) {
    const isRole:boolean = await store.getters['auth/getUserDetails'].hasRole(roleNames)
    if (isRole) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

const currentEmployeeId = async () => {
  if (await store.getters['auth/getUserId']) {
    return await store.getters['auth/getUserDetails'].objects.user.get('employee').id
  } else {
    return ''
  }
}

export { isLoggedIn, hasRole, currentEmployeeId }
