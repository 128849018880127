import { boot } from 'quasar/wrappers'
import * as Sentry from '@sentry/vue'

import { BrowserTracing } from '@sentry/tracing'

// "async" is optional;
// more info on params: https://v2.quasar.dev/quasar-cli/boot-files
export default boot(({ app, router/*, router */ } /* { app, router, ... } */) => {
  // something to do
  if (process.env.SENTRY_DSN) {
    Sentry.init({
      app,
      dsn: process.env.SENTRY_DSN,
      // @ts-ignore
      // eslint-disable-next-line import/namespace
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ['localhost', 'backend.app.spoortech.com', 'backend.dev.app.spoortech.com', /^\//]
        })
      ],
      tracesSampleRate: process.env.SENTRY_ENVIRONMENT === 'development' ? 1 : 0.1,
      attachProps: true,
      trackComponents: true,
      environment: process.env.SENTRY_ENVIRONMENT,
      release: process.env.SENTRY_RELEASE,
      autoSessionTracking: false,
      beforeBreadcrumb: (breadcrumb, hint) => {
        if (breadcrumb.category === 'xhr') {
          const data = {
            requestBody: hint?.xhr.__sentry_xhr__.body,
            response: hint?.xhr.response,
            responseUrl: hint?.xhr.responseUrl
          }
          breadcrumb.data = { ...breadcrumb.data, ...data }
        }
        return breadcrumb
      }
      // tracesSampler: (samplingContext) => {
      //   console.log(samplingContext)
      //   return 1
      // }
      // beforeSendTransaction (event) {
      //   console.log(event)
      //   return event
      // }
    })
  }
})

// const filterEventToSend = (event) => {
//   if (event.title.includes('ResizeObserver loop completed')){
//     return null;
//   }
//
//   // No exclusion matches; send the event to Sentry
//   return event
// }

export { Sentry }
