import { Role, User } from 'parse'

type LoginCredentials = {
  identifier: string,
  password: string
}

type UserObjects = {
  user?:User,
  roles?:Role[]
}

type UserDetails = {
  id?: string,
  name?: string,
  roles?: string[],
  objects?: UserObjects,
  hasRole?: (roleNamesArr:string[]) => boolean,
}

interface AuthStateInterface {
  // userDetails: Record<string, unknown>,
  userDetails: UserDetails,
  authError: Record<string, unknown>
}

enum AuthMutationList {
  SET_ERRORS ='SET_ERRORS',
  SET_USER = 'SET_USER'
}

enum AuthActionList {
  makeUserData = 'makeUserData',
  loginUser = 'loginUser',
  clearAuthErrors = 'clearAuthErrors',
  logoutUser = 'logoutUser',
  currentUser = 'currentUser',
  redirectUser = 'redirectUser',
  setUserDetails = 'setUserDetails'
}

enum AuthGetterList {
  getUserDetails = 'getUserDetails',
  getUserId = 'getUserId'
}

export {
  AuthStateInterface,
  AuthMutationList,
  AuthActionList,
  AuthGetterList,
  UserDetails,
  LoginCredentials
}
